import axios, {HttpStatusCode} from 'axios';

const axiosAuthorized = axios.create();

axiosAuthorized.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });

axiosAuthorized.interceptors.response.use(
    response => {
        return response;
    },error => {

        if (error?.response?.status === HttpStatusCode?.Unauthorized) {
            localStorage.removeItem('access_token');
        }
        // return Promise.reject(error);
    }
);

export default axiosAuthorized;

