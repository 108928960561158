import React from "react";
import '../../common-widgets/notification-badge.css'

export const baseUrlApp = process.env.REACT_APP_FOOTBALL_PREDICTIONS_API_BASE_URL;
// export const baseUrlApp = "http://localhost:81/football-predictions-api";
export const baseUrlUaa = process.env.REACT_APP_UAA_SERVICE_BASE_URL;
export const authorizeUrl = baseUrlUaa + '/oauth/authorize';
export const accessTokenUrl = baseUrlUaa + '/oauth/token';
export const clientId = 'football-predictions-client';
export const clientSecret = 'P@ssword123';
export const redirectUri = '/fixtures';

export const routes = [

]



