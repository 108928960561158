import React, {useEffect, useState} from 'react';
import axios, {HttpStatusCode} from 'axios';
import {useNavigate} from 'react-router-dom';
import './loginUtil.css';
import {baseUrlApp} from "../../../user-management/configurations/config";

export const loginRoute="/login";

function LoginUtil({accessTokenUrl: accessTokenUrl,
               clientId: clientId,
               clientSecret: clientSecret,
               redirectUri: redirectUri}) {

    const navigate = useNavigate();

    const [tokenObject, setTokenObject] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');

    /*useEffect(() => {
        let timeoutId;

        const refreshAccessToken = () => {

            axios.post(accessTokenUrl, {
                grant_type: 'refresh_token',
                refresh_token: tokenObject?.refresh_token,
                client_id: clientId,
                client_secret: clientSecret,
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }).then(response => {

                localStorage.setItem('tokenObject', response.data);
                setRefreshToken(response?.data.refresh_token)
                // Set up the next token refresh based on the token's expiration time minus some buffer time
                const expiresIn = response?.data?.expires_in; // token expiration time in seconds
                const refreshBuffer = 60; // refresh token 1 minute before expiration
                const refreshTime = (expiresIn - refreshBuffer) * 1000; // convert to milliseconds

                timeoutId = setTimeout(refreshAccessToken, refreshTime);

                navigate(redirectUri);
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === HttpStatusCode.BadRequest) {
                        setLoginMessage(error.response.data.description);
                    } else {
                        setLoginMessage(error.response);
                    }
                } else if (error.request) {
                    setLoginMessage(error.request);
                } else {
                    setLoginMessage(error);
                }
            });
        };

        // Set up the initial token refresh based on the token's expiration time minus some buffer time
        const expiresIn = 3600; // example token expiration time in seconds
        const refreshBuffer = 60; // refresh token 1 minute before expiration
        const refreshTime = (expiresIn - refreshBuffer) * 1000; // convert to milliseconds

        timeoutId = setTimeout(refreshAccessToken, refreshTime);

        // Clean up the timeout on component unmount or token update
        return () => {
            clearTimeout(timeoutId);
        };
    }, [refreshToken]);*/

    const handleLogin = () => {
        axios.post(accessTokenUrl, {
            grant_type: 'password',
            username: username,
            password: password,
            client_id: clientId,
            client_secret: clientSecret
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then(response => {
            localStorage.setItem('access_token', response?.data?.access_token);
            navigate(redirectUri);
        }).catch(error =>{
            if (error.response) {
                if (error.response.status === HttpStatusCode.BadRequest) {
                    setLoginMessage(error.response.data.description);
                } else {
                    setLoginMessage(error.response.data);
                }
            } else if (error.request) {
                setLoginMessage(error.request);
            } else {
                setLoginMessage(error);
            }
        });
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateUsernameAndPassword(username, password)) {
            handleLogin();
        } else {
            setLoginMessage('Invalid username or password.');
        }
    };

    const validateUsernameAndPassword = (username, password) => {
        return username.trim() !== '' && password.trim() !== '';
    };

    const loginFormStyle = {
        display:'flex',
        flexDirection: 'column',
        color: 'white',
        alignItems: 'center',
        flex:1,
        flexWrap: 'wrap',
        margin: '0% auto 0',
        border: 'solid',
        background: '#324aa8',
        justifyItems: 'center',
        width: window.innerWidth <= 700 ? '90%' : '20%',
        borderRadius: 10,
        justifyContent: 'center',
    }

    const loginMessageStyle = {
        marginTop: 10,
        color: '#ff0000',
        width: '100%'
    }

    return (
            <form onSubmit={handleSubmit} style={loginFormStyle} className={'login-item'}>
                <h2>Login</h2>
                <img width={'30%'} src={'http://ndivs.com:81/malaika-website-api/storage/image-assets/bongotips_nav_logo_orange.png'}/>
                <img width={'30%'} src={'http://ndivs.com:81/malaika-website-api/storage/image-assets/bongotips_nav_logo_words.png'}/>
                <br/>
                <label htmlFor="username">Username</label>
                <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required/>

                <label htmlFor="password">Password</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <br/>
                <button className={'login-button'} type="submit">Login</button>
                <p style={loginMessageStyle}>{loginMessage}</p>
            </form>
    );
}

export default LoginUtil;