import React from "react";

export default function PredictionRow({prediction: prediction, homeTeam: homeTeam, awayTeam: awayTeam}){
    return (
        <tr style={{fontSize:12, color: prediction?.homeGoals === null || prediction?.awayGoals === null ? 'black' : "white", whiteSpace: 'nowrap'}}>
            <td style={{color:"black"}} className="text-align-center">{prediction?.date + ' ' + prediction?.time}</td>
            <td style={{color:"black", fontWeight: prediction?.homeTeam === homeTeam || prediction?.homeTeam === awayTeam ? "bold" : null}} className="text-align-right">{prediction?.homeTeam}</td>
            <td style={{color:"black", fontSize: 12, fontWeight:"bold", padding: 5}} className="text-align-center">{(prediction?.homeGoals===null ? '' : prediction?.homeGoals)} - {(prediction?.awayGoals ===null ? '' : prediction?.awayGoals)}</td>
            <td style={{color:"black", fontWeight: prediction?.awayTeam === homeTeam || prediction?.awayTeam === awayTeam ? "bold" : null}} className="text-align-left">{prediction?.awayTeam}</td>
            <td className="text-align-center"  style={{backgroundColor: prediction?.correctScoreStatus == 'Won' ? 'green' : prediction?.correctScoreStatus == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.correctScoreHomeGoals===null ? '' : prediction?.correctScoreHomeGoals} - {prediction?.correctScoreAwayGoals===null ? '' : prediction?.correctScoreAwayGoals}</p>
                <p>{prediction?.correctScoreProbability}</p>
            </td>
            <td  className="text-align-center" style={{backgroundColor: prediction?.normalStatus == 'Won' ? 'green' : prediction?.normalStatus == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.normalTip}</p>
                <p>{prediction?.normalTipProbability}</p>
            </td>
            <td  className="text-align-center" style={{backgroundColor: prediction?.ggStatus == 'Won' ? 'green' : prediction?.ggStatus == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ggTip}</p>
                <p>{prediction?.ggTipProbability}</p>
            </td>
            <td  className="text-align-center" style={{backgroundColor: prediction?.dcStatus == 'Won' ? 'green' : prediction?.dcStatus == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.dcTip}</p>
                <p>{prediction?.dcTipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.ou05Status == 'Won' ? 'green' : prediction?.ou05Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ou05Tip}</p>
                <p>{prediction?.ou05TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.ou15Status == 'Won' ? 'green' : prediction?.ou15Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ou15Tip}</p>
                <p>{prediction?.ou15TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.ou25Status == 'Won' ? 'green' : prediction?.ou25Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ou25Tip}</p>
                <p>{prediction?.ou25TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.ou35Status == 'Won' ? 'green' : prediction?.ou35Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ou35Tip}</p>
                <p>{prediction?.ou35TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.ou45Status == 'Won' ? 'green' : prediction?.ou45Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ou45Tip}</p>
                <p>{prediction?.ou45TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.ou55Status == 'Won' ? 'green' : prediction?.ou55Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.ou55Tip}</p>
                <p>{prediction?.ou55TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.dcOu15Status == 'Won' ? 'green' : prediction?.dcOu15Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.dcOu15Tip}</p>
                <p>{prediction?.dcOu15TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.normalOrOver25Status == 'Won' ? 'green' : prediction?.normalOrOver25Status == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.normalOrOver25Tip}</p>
                <p>{prediction?.normalOrOver25TipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.normalOrGgStatus == 'Won' ? 'green' : prediction?.normalOrGgStatus == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.normalOrGgTip}</p>
                <p>{prediction?.normalOrGgTipProbability}</p>
            </td>
            <td className="text-align-center" style={{backgroundColor: prediction?.over25OrGgStatus == 'Won' ? 'green' : prediction?.over25OrGgStatus == 'Lost' ? 'red' : 'orange'}}>
                <p>{prediction?.over25OrGgTip}</p>
                <p>{prediction?.over25OrGgTipProbability}</p>
            </td>
        </tr>
    );
}