import React from "react";
import customDialogStyles from './customDialog.module.css';
export default function CustomDialog({open:open, onClose:onClose, data:data, content:content}){
    if (!open) {
        return null;
    }
    return (data &&
        <div className={customDialogStyles.customDialog}>
            <div className={customDialogStyles.dialogHeader}>
                <button className={customDialogStyles.closeButton} onClick={onClose}>
                    X
                </button>
            </div>
            <div className={customDialogStyles.dialogContent}>
                {content}
            </div>
        </div>
    );
}