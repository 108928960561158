import './navigationBar.module.css'
import navigationBarStyles from './navigationBar.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import {fixtureRoute} from "../../predictions/fixtures";
import {useNavigate} from "react-router-dom";
import {baseUrlApp} from "../../user-management/configurations/config";

export default function NavigationBar({toggleSubMenu: toggleSubMenu}){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const menuLinks = document.getElementById('menuLinks');
    let menuDisplay = useState('flex');

    if(menuLinks){
        const computedStyle = window.getComputedStyle(menuLinks);
        menuDisplay = computedStyle.getPropertyValue('display');
        if(isMenuOpen && window.innerWidth <= 767){
            menuDisplay = 'flex';
        }else if(!isMenuOpen && window.innerWidth <= 767){
            menuDisplay = 'none';
        }
    }
    const [isTransparent, setIsTransparent] = useState(true);

    useEffect(() => {

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsTransparent(scrollTop === 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigator = useNavigate();
   return(
       <nav className={navigationBarStyles.horizontalNav}>
           <h2 onClick={()=> navigator(fixtureRoute.route)}><img style={{height:30, cursor:"pointer"}} src={'http://ndivs.com:81/malaika-website-api/storage/image-assets/bongotips_nav_logo_orange.png'}/><img style={{height:25, cursor:"pointer"}} src={'http://ndivs.com:81/malaika-website-api/storage/image-assets/bongotips_nav_logo_words.png'}/></h2>
           <div id={'menuLinks'} className={navigationBarStyles.menuLinks} style={{display: menuDisplay}}>
               <ul>

               </ul>
           </div>
           {/*<FontAwesomeIcon id={'menu-button'} icon={showMenu ? faClose : faBars} style={{right:20, position: 'absolute', cursor: "pointer"}}
                            onClick={() => setShowMenu(!showMenu)}
           />*/}
    </nav>
   );
}

