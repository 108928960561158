import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ProtectedRoutes from "./user-management/configurations/protected-routes";
import FixturesManager, {fixtureManagerRoute} from "./predictions/fixtures-manager";
import Fixtures, {fixtureRoute} from "./predictions/fixtures";
import Layout from "./common-widgets/Layouts/layout";
import Login, {loginRoute} from "./predictions/login";

const footballPredictionsRouting = (
    <Router basename={'betting-tips'}>
        <Layout>
            <Routes >
                <Route path='/' element={<Fixtures/>} />
                <Route path={loginRoute} element={<Login/>} />
                <Route path={fixtureRoute} element={<Fixtures/>} />
                <Route path={fixtureManagerRoute} element={<FixturesManager/>} />

                {/*<Route element={<ProtectedRoutes />}>
                    <Route path={fixtureRoute} element={<FixturesManager/>} />
                </Route>*/}
            </Routes>
        </Layout>
    </Router>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(footballPredictionsRouting);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
