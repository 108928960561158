import React, {useCallback, useEffect, useRef, useState} from 'react';
import './fixtures.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {format, isValid, parse} from 'date-fns';
import axiosAuthorized from "../user-management/configurations/axiosAuthorized";
import FixtureRowDetails from "./fixture-row-details";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faClose, faSort, faSync} from "@fortawesome/free-solid-svg-icons";
import {
    ddMMyyyyFormatter,
    ddMMyyyyHHmmFormatter, yyyyMMddFormatter
} from "../react-shared-utils/user-management/configurations-util/config-util";
import {baseUrlApp} from "../user-management/configurations/config";

export const fixtureManagerRoute = "/fixture-manager";
function FixturesManager() {

    let [fixtures, setFixtures] = useState([]);
    const [fixtureSummary, setFixtureSummary] = useState({});
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [useLocalData, setUseLocalData] = useState(true)
    const [numberOfLatestGames, setNumberOfLatestGames] = useState(20);
    const [numberOfLatestH2hGames, setNumberOfLatestH2hGames] = useState(10);
    const url = baseUrlApp.concat('/api/v1/fixture/soccer-vista/fixture-predictions/from-date/'+ format(selectedFromDate, ddMMyyyyFormatter) + '/to-date/' + format(selectedToDate, ddMMyyyyFormatter) + '?useLocalData=' + useLocalData + '&numberOfLatestH2hGames=' + numberOfLatestH2hGames + '&numberOfLatestGames=' + numberOfLatestGames);
    const [sortAscDirection, setSortAscDirection] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("normal");
    const fixtureTableRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [h2hCount, setH2hCount] = useState(6);
    const [homeTeamFixtureCount, setHomeTeamFixtureCount] = useState(6);
    const [awayTeamFixtureCount, setAwayTeamFixtureCount] = useState(6);
    const [showH2h, setShowH2h] = useState(false);


    const callRunTimeErrorAlert = (message) => alert('Runtime error: ' + message);

    const sortTableByColIndex = colIndex => {
        if (fixtureTableRef.current) {

            const tbody = fixtureTableRef.current.querySelector("tbody");
            const rows = Array.from(tbody.getElementsByTagName("tr"));

            if(rows.length > 0){
                rows.sort((a, b) => {
                    const aValue = a.cells[colIndex].textContent;
                    const bValue = b.cells[colIndex].textContent;

                    if((typeof aValue === 'number' && typeof bValue === 'number')){
                        return sortAscDirection ? bValue - aValue : aValue - bValue;
                    }else if (typeof aValue === 'string' && typeof bValue === 'string'){
                        return sortAscDirection ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    }else if(isValid(parse(aValue, ddMMyyyyHHmmFormatter, new Date())) && isValid(parse(bValue, ddMMyyyyHHmmFormatter, new Date()))){
                        return sortAscDirection ? bValue - aValue : aValue - bValue;
                    }
                });
                rows.forEach(row => tbody.appendChild(row));
                setSortAscDirection(!sortAscDirection);
            }else{
                callRunTimeErrorAlert("could not sort empty table")
            }
        }else {
            callRunTimeErrorAlert('reference not found');
        }
    }

    const toggleColumn = useCallback (() => {
        if (fixtureTableRef.current) {
            const table = fixtureTableRef.current;
            const columnsToShow = table.querySelectorAll(`th.${selectedCategory}, td.${selectedCategory}`);
            const columnsToHide = [] /*table.querySelectorAll(`th[class]:not(.${selectedCategory}), td[class]:not(.${selectedCategory})`)*/;

            const listOfClassesToTogglr = ['normal', 'gg', 'correctScore', 'dc', 'dcOu15', 'ou05', 'ou05', 'ou15','ou25','ou35','ou45','ou55', 'normalOrOver25', 'normalOrGg', 'over25OrGg'];

            listOfClassesToTogglr.forEach(item => {
                const elementsWithClass = table.querySelectorAll(`.${item}`);
                if(item !== selectedCategory){
                    columnsToHide.push(...elementsWithClass);
                }
            })

            columnsToShow.forEach(column => {
                column.classList.remove('hidden');
            });

            columnsToHide.forEach(column => {
                column.classList.add('hidden');
            });

            let columnIndexesToShow = Array.from(columnsToShow).map(column => {
                return Array.from(column.parentNode.children).indexOf(column);
            });
            columnIndexesToShow = columnIndexesToShow.filter((value, index, self) => self.indexOf(value) === index);
        }
    }, [selectedCategory])

    const getFixtureByDate = useCallback(() => {
        setFixtures([]);
        setSelectedRowData(null);
        axiosAuthorized.get(url)
            .then((response) => {
                setFixtureSummary(response.data.summary)
                const newFix = setFixtureNewFields(response?.data?.fixture)
                setFixtures(newFix);
            }).catch((error) => {
                setError(error.toString());
            }).finally(() =>{
                setLoading(false);
        });
    }, [selectedToDate])

    useEffect(() => {
        getFixtureByDate();
    }, [getFixtureByDate]);

    useEffect(() =>{
        toggleColumn();
    }, )

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
    };

    const handleCloseDialog = () => {
        setSelectedRowData(null);
    };

    const fixtureResult = (teamName, fixture) => {
       const prevResult = {
            normal : null,
            gg : null,
            ou25 : null,
            ou15 : null
        }
        if((teamName === fixture?.homeTeam && fixture?.homeGoals > fixture?.awayGoals) ||
            (teamName === fixture?.awayTeam && fixture?.awayGoals > fixture?.homeGoals))
            prevResult.normal = 'W';
        if((teamName === fixture?.homeTeam && fixture?.homeGoals < fixture?.awayGoals) ||
            (teamName === fixture?.awayTeam && fixture?.awayGoals < fixture?.homeGoals))
            prevResult.normal =  'L';
        if(fixture?.awayGoals === fixture?.homeGoals)
            prevResult.normal =  'D';


        if(fixture?.awayGoals > 0 && fixture?.homeGoals > 0)
            prevResult.gg =  'GG';
        if(fixture?.awayGoals === 0 || fixture?.homeGoals === 0)
            prevResult.gg =  'NG';

        if(fixture?.awayGoals + fixture?.homeGoals >= 3)
            prevResult.ou25 =  'O';
        if(fixture?.awayGoals + fixture?.homeGoals < 3)
            prevResult.ou25 =  'U';

        if(fixture?.awayGoals + fixture?.homeGoals >= 2)
            prevResult.ou15 =  'O';
        if(fixture?.awayGoals + fixture?.homeGoals < 2)
            prevResult.ou15 =  'U';

        return prevResult;
    }

    const setFixtureNewFields = (fixturesToUpdate) => {
        let updatedFixture = fixturesToUpdate?.map(fixture => {
            const latestFixturesHomeTeam = fixture?.latestGames?.filter(latest => latest?.homeTeam?.concat(latest?.awayTeam)?.includes(fixture?.homeTeam)
            )?.sort((a, b) => parse(b?.date, yyyyMMddFormatter, new Date()) - parse(a?.date, yyyyMMddFormatter, new Date()));

            const latestFixturesAwayTeam = fixture?.latestGames?.filter(latest => latest?.homeTeam?.concat(latest?.awayTeam)?.includes(fixture?.awayTeam)
            )?.sort((a, b) => parse(b?.date, yyyyMMddFormatter, new Date()) - parse(a?.date, yyyyMMddFormatter, new Date()));

            const latestFixturesH2h = fixture?.latestGames?.filter(latest => latest?.homeTeam?.concat(latest?.awayTeam)?.includes(fixture?.homeTeam) && latest?.homeTeam?.concat(latest?.awayTeam)?.includes(fixture?.awayTeam)
            )?.sort((a, b) => parse(b?.date, yyyyMMddFormatter, new Date()) - parse(a?.date, yyyyMMddFormatter, new Date()));

            return {
                ...fixture,
                latestFixturesHomeTeam: latestFixturesHomeTeam,
                latestFixturesAwayTeam: latestFixturesAwayTeam,
                latestFixturesH2h
            }
        })

        updatedFixture = updatedFixture?.map(fixture => {

            const homeTeamStats = getStatistics(fixture?.homeTeam, fixture?.latestFixturesHomeTeam);
            const awayTeamStats = getStatistics(fixture?.awayTeam, fixture?.latestFixturesAwayTeam);
            const homeTeamH2hStats = getStatistics(fixture?.homeTeam, fixture?.latestFixturesH2h);
            const awayTeamH2hStats = getStatistics(fixture?.awayTeam, fixture?.latestFixturesH2h);

            return{
                ...fixture,
                homeTeamStats: homeTeamStats,
                awayTeamStats: awayTeamStats,
                homeTeamH2hStats: homeTeamH2hStats,
                awayTeamH2hStats: awayTeamH2hStats
            }
        })

        return updatedFixture;
    }

    const getStatistics = (teamName, latestFixtures) => {

        const statistics = {
            winsCount: 0,
            loseCount: 0,
            drawCount: 0,
            ggCount: 0,
            ngCount: 0,
            o25Count: 0,
            u25Count: 0,
            o15Count: 0,
            u15Count: 0
        }

        latestFixtures?.slice(0, 6)?.forEach(latest => {
            if(fixtureResult(teamName, latest)?.normal === 'W')
                statistics.winsCount++;
            if(fixtureResult(teamName, latest)?.normal === 'L')
                statistics.loseCount++;
            if(fixtureResult(teamName, latest)?.normal === 'D')
                statistics.drawCount++;

            if(fixtureResult(teamName, latest)?.gg === 'GG')
                statistics.ggCount++;
            if(fixtureResult(teamName, latest)?.gg === 'NG')
                statistics.ngCount++;

            if(fixtureResult(teamName, latest)?.ou25 === 'O')
                statistics.o25Count++;
            if(fixtureResult(teamName, latest)?.ou25 === 'U')
                statistics.u25Count++;

            if(fixtureResult(teamName, latest)?.ou15 === 'O')
                statistics.o15Count++;
            if(fixtureResult(teamName, latest)?.ou15 === 'U')
                statistics.u15Count++;
        });

        return statistics;
    }

    const getHomeStats = stats => {

        return (
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <div style={{padding:1, margin:1, borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-end", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>O1|</span>
                        <span>U1</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${stats?.o15Count > stats?.u15Count ? 'won-indicator' : stats?.o15Count < stats?.u15Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.o15Count}</span>
                        <span className={`result-span ${stats?.u15Count > stats?.o15Count ? 'won-indicator' : stats?.u15Count < stats?.o15Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.u15Count}</span>
                    </span>
                </div>

                <div style={{padding:1, margin:1, borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-end", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>O2|</span>
                        <span>U2</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${stats?.o25Count > stats?.u25Count ? 'won-indicator' : stats?.o25Count < stats?.u25Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.o25Count}</span>
                        <span className={`result-span ${stats?.u25Count > stats?.o25Count ? 'won-indicator' : stats?.u25Count < stats?.o25Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.u25Count}</span>
                    </span>
                </div>
                <div style={{padding:1, margin:1,borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-end", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>G|</span>
                        <span>N</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${stats?.ggCount > stats?.ngCount ? 'won-indicator' : stats?.ggCount < stats?.ngCount ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.ggCount}</span>
                        <span className={`result-span ${stats?.ngCount > stats?.ggCount ? 'won-indicator' : stats?.ngCount < stats?.ggCount ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.ngCount}</span>
                    </span>
                </div>
                <div style={{padding:1, margin:1,borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-end", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>L</span>
                        <span>| D |</span>
                        <span>W</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${
                            stats?.loseCount > stats?.drawCount && stats?.loseCount > stats?.winsCount ? 'won-indicator' :
                                stats?.loseCount < stats?.drawCount && stats?.loseCount < stats?.winsCount ? 'lost-indicator' : 'draw-indicator'
                        }`}>{stats?.loseCount}</span>
                        <span className={`result-span ${
                            stats?.drawCount > stats?.loseCount && stats?.drawCount > stats?.winsCount ? 'won-indicator' :
                                stats?.drawCount < stats?.loseCount && stats?.drawCount < stats?.winsCount ? 'lost-indicator' : 'draw-indicator'
                        }`}>{stats?.drawCount}</span>
                        <span className={`result-span ${
                            stats?.winsCount > stats?.loseCount && stats?.winsCount >  stats?.drawCount ? 'won-indicator' :
                                stats?.winsCount < stats?.loseCount && stats?.winsCount <  stats?.drawCount ? 'lost-indicator' : 'draw-indicator'
                        }`}>{stats?.winsCount}</span>
                    </span>
                </div>
            </div>
        );
    }
    
    const getAwayStats = (stats) => {
        return (
            <div style={{display:"flex", justifyContent:"flex-start"}}>
                <div style={{padding:1, margin:1,borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-start", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>W</span>
                        <span>| D |</span>
                        <span>L</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${
                            stats?.winsCount > stats?.loseCount && stats?.winsCount >  stats?.drawCount ? 'won-indicator' :
                                stats?.winsCount < stats?.loseCount && stats?.winsCount <  stats?.drawCount ? 'lost-indicator' : 'draw-indicator'
                        }`}>{stats?.winsCount}</span>
                        <span className={`result-span ${
                            stats?.drawCount > stats?.loseCount && stats?.drawCount > stats?.winsCount ? 'won-indicator' :
                                stats?.drawCount < stats?.loseCount && stats?.drawCount < stats?.winsCount ? 'lost-indicator' : 'draw-indicator'
                        }`}>{stats?.drawCount}</span>
                        <span className={`result-span ${
                            stats?.loseCount > stats?.drawCount && stats?.loseCount > stats?.winsCount ? 'won-indicator' :
                                stats?.loseCount < stats?.drawCount && stats?.loseCount < stats?.winsCount ? 'lost-indicator' : 'draw-indicator'
                        }`}>{stats?.loseCount}</span>
                    </span>
                </div>
                <div style={{padding:1, margin:1,borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-end", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>G|</span>
                        <span>N</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${stats?.ggCount > stats?.ngCount ? 'won-indicator' : stats?.ggCount < stats?.ngCount ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.ggCount}</span>
                        <span className={`result-span ${stats?.ngCount > stats?.ggCount ? 'won-indicator' : stats?.ngCount < stats?.ggCount ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.ngCount}</span>
                    </span>
                </div>
                <div style={{padding:1, margin:1, borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-start", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>O2|</span>
                        <span>U2</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${stats?.o25Count > stats?.u25Count ? 'won-indicator' : stats?.o25Count < stats?.u25Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.o25Count}</span>
                        <span className={`result-span ${stats?.u25Count > stats?.o25Count ? 'won-indicator' : stats?.u25Count < stats?.o25Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.u25Count}</span>
                    </span>
                </div>

                <div style={{padding:1, margin:1, borderRadius:3, display:"flex", flexDirection: "column", justifyContent: "flex-start", alignItems:"center", border: "solid", borderWidth:0.5}}>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span>O1|</span>
                        <span>U1</span>
                    </span>
                    <span style={{display:"flex", flexDirection: "row",}}>
                        <span className={`result-span ${stats?.o15Count > stats?.u15Count ? 'won-indicator' : stats?.o15Count < stats?.u15Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.o15Count}</span>
                        <span className={`result-span ${stats?.u15Count > stats?.o15Count ? 'won-indicator' : stats?.u15Count < stats?.o15Count ? 'lost-indicator' : 'draw-indicator'}`}>{stats?.u15Count}</span>
                    </span>
                </div>

            </div>
        );
    }

    const handleStatsSort = () => {
        const sorted = [...fixtures]
        sorted.sort((a, b) => {
            const aValue = showH2h ? a?.homeTeamH2hStats?.winsCount : a?.homeTeamStats?.winsCount
            const bValue = showH2h ? b?.homeTeamH2hStats?.winsCount : b?.homeTeamStats?.winsCount

            return sortAscDirection ? bValue - aValue : aValue - bValue
        })

        setSortAscDirection(!sortAscDirection)
        setFixtures(sorted);
    }

    function countGoals(actions, isFirstHalf) {
        if (!actions) return 0;

        return actions
            .filter(action => action?.goal && isFirstHalf ? action.minute <= 45 : action.minute > 45)
            .length;
    }

    return (
        <div style={{margin: '0 auto', display:"flex", flexDirection:"column", position:"relative"}}>
            <div style={{ display:"flex"}}>
                <div style={{display:"flex", flexDirection: "column", width: '30%'}}>
                    <FontAwesomeIcon icon={faCalculator}/>
                    <label htmlFor={"numberOfH2hGames"}>H2H Included</label>
                    <input name="numberOfH2hGames" type={"number"} value={numberOfLatestH2hGames} onChange={event => setNumberOfLatestH2hGames(event.target.value)}/>
                    <label htmlFor={"numberOfLatestGames"}>Latest Games Included</label>
                    <input name="numberOfLatestGames" type={"number"} value={numberOfLatestGames} onChange={ event => setNumberOfLatestGames(event.target.value)}/>
                </div>
                <div style={{width: '30%'}}>
                    <FontAwesomeIcon icon={faSort}/>
                    <button onClick={handleStatsSort}>sort</button>
                    <a onClick={() => setShowH2h(!showH2h)} style={{textDecoration: showH2h ? "underline" : "none"}}>H2H</a>
                </div>
                <div style={{display:"flex", alignSelf:"end", flexDirection:"column", gap:5,margin:5, padding:5, width: '30%', border: "thin solid", borderRadius:3 }}>
                    <div style={{display:"flex", justifyItems: "stretch", alignItems:"center", gap:2}}>
                        <DatePicker
                            selected={selectedFromDate}
                            onChange={date => setSelectedFromDate(date)}
                            dateFormat={ddMMyyyyFormatter}
                            placeholderText="From date"/><br/>
                        <DatePicker
                            selected={selectedToDate}
                            onChange={date => setSelectedToDate(date)}
                            dateFormat={ddMMyyyyFormatter}
                            placeholderText="To Date"/>
                        <FontAwesomeIcon icon={faSync} onClick={()=>setUseLocalData(!useLocalData)} style={{color: useLocalData ? null : "green", fontSize:"medium"}}/>
                    </div>

                    <select id="columns" onChange={event => setSelectedCategory(event.target.value)} defaultValue="correctScore">
                        <option value="normal" >1X2 Won: {fixtureSummary?.wonCountNormal} Lost: {fixtureSummary?.lostCountNormal}</option>
                        <option value="gg">GG Won: {fixtureSummary?.wonCountGG} Lost: {fixtureSummary?.lostCountGG}</option>
                        <option value="ou05">OU 0.5 Won: {fixtureSummary?.wonCountOu05} Lost: {fixtureSummary?.lostCountOu05}</option>
                        <option value="ou15">OU 1.5 Won: {fixtureSummary?.wonCountOu15} Lost: {fixtureSummary?.lostCountOu15}</option>
                        <option value="ou25">OU 2.5 Won: {fixtureSummary?.wonCountOu25} Lost: {fixtureSummary?.lostCountOu25}</option>
                        <option value="ou35">OU 3.5 Won: {fixtureSummary?.wonCountOu35} Lost: {fixtureSummary?.lostCountOu35}</option>
                        <option value="ou45">OU 4.5 Won: {fixtureSummary?.wonCountOu45} Lost: {fixtureSummary?.lostCountOu45}</option>
                        <option value="ou55">OU 5.5 Won: {fixtureSummary?.wonCountOu55} Lost: {fixtureSummary?.lostCountOu55}</option>
                        <option value="dc">DC Won: {fixtureSummary?.wonCountDc} Lost: {fixtureSummary?.lostCountDc}</option>
                        <option value="dcOu15">DC & OU 1.5 Won: {fixtureSummary?.wonCountDcOu15} Lost: {fixtureSummary?.lostCountDcOu15}</option>
                        <option value="normalOrOver25">1X2 or Over 2.5 Won: {fixtureSummary?.wonCountNormalOrOver25} Lost: {fixtureSummary?.lostCountNormalOrOver25}</option>
                        <option value="normalOrGg">1X2 or GG Won: {fixtureSummary?.wonCountNormalOrGg} Lost: {fixtureSummary?.lostCountNormalOrGg}</option>
                        <option value="over25OrGg">GG or OU 2.5 Won: {fixtureSummary?.wonCountOver25OrGg} Lost: {fixtureSummary?.lostCountOver25OrGg}</option>
                        <option value="correctScore">Correct Score</option>
                    </select>
                </div>
            </div>
            <div>
                {
                    loading ? <p>Loading...</p> : error ? <p>Error: error</p> :
                        <div>
                            <div style={{ position: 'relative', display: "flex", flexDirection: "row"}}>
                                <table id="data-table" ref={fixtureTableRef} style={{width: selectedRowData ? '70%' : '100%', maxheight: 200, overflowY:"auto"}}>
                                    <thead>
                                    <tr>
                                        <th onClick={() => sortTableByColIndex(0)} width={'25%'}></th>
                                        <th onClick={() => sortTableByColIndex(1)} width={'30%'}></th>
                                        <th onClick={() => sortTableByColIndex(2)} width={'8%'}>FT</th>
                                        <th onClick={() => sortTableByColIndex(3)} width={'30%'}></th>
                                        <th onClick={() => sortTableByColIndex(4)} width={'8%'}>AVG</th>

                                        <th onClick={() => sortTableByColIndex(5)} className="normal" width={'5%'}>1X2</th>
                                        <th onClick={() => sortTableByColIndex(6)} className="normal" width={'5%'}>%</th>
                                        <th onClick={() => sortTableByColIndex(7)} className="normal" width={'5%'}></th>

                                        <th onClick={() => sortTableByColIndex(8)} className="gg" width={'3%'}>GG</th>
                                        <th onClick={() => sortTableByColIndex(9)} className="gg" width={'3%'}>%</th>
                                        <th onClick={() => sortTableByColIndex(10)} className="gg" width={'5%'}></th>

                                        <th onClick={() => sortTableByColIndex(11)} className="ou05" width={'5%'}>OU 0.5</th>
                                        <th onClick={() => sortTableByColIndex(12)} className="ou05" width={'5%'}>%</th>
                                        <th onClick={() => sortTableByColIndex(13)} className="ou05" width={'5%'}></th>

                                        <th onClick={() => sortTableByColIndex(14)} className="ou15">OU 1.5</th>
                                        <th onClick={() => sortTableByColIndex(15)} className="ou15">%</th>
                                        <th onClick={() => sortTableByColIndex(16)} className="ou15"></th>

                                        <th onClick={() => sortTableByColIndex(17)} className="ou25">OU 2.5</th>
                                        <th onClick={() => sortTableByColIndex(18)} className="ou25">%</th>
                                        <th onClick={() => sortTableByColIndex(19)} className="ou25"></th>

                                        <th onClick={() => sortTableByColIndex(20)} className="ou35">OU 3.5</th>
                                        <th onClick={() => sortTableByColIndex(21)} className="ou35">%</th>
                                        <th onClick={() => sortTableByColIndex(22)} className="ou35"></th>

                                        <th onClick={() => sortTableByColIndex(23)} className="ou45">OU 4.5</th>
                                        <th onClick={() => sortTableByColIndex(24)} className="ou45">%</th>
                                        <th onClick={() => sortTableByColIndex(25)} className="ou45"></th>

                                        <th onClick={() => sortTableByColIndex(26)} className="ou55">OU 5.5</th>
                                        <th onClick={() => sortTableByColIndex(27)} className="ou55">%</th>
                                        <th onClick={() => sortTableByColIndex(28)} className="ou55"></th>

                                        <th onClick={() => sortTableByColIndex(29)} className="dc">DC</th>
                                        <th onClick={() => sortTableByColIndex(30)} className="dc">%</th>
                                        <th onClick={() => sortTableByColIndex(31)} className="dc"></th>

                                        <th onClick={() => sortTableByColIndex(32)} className="dcOu15">DC & OU 1.5</th>
                                        <th onClick={() => sortTableByColIndex(33)} className="dcOu15">%</th>
                                        <th onClick={() => sortTableByColIndex(34)} className="dcOu15"></th>

                                        <th onClick={() => sortTableByColIndex(35)} className="correctScore">SCORE</th>
                                        <th onClick={() => sortTableByColIndex(36)} className="correctScore">%</th>
                                        <th onClick={() => sortTableByColIndex(37)} className="correctScore"></th>

                                        <th onClick={() => sortTableByColIndex(38)} className="normalOrOver25">1X2 or Over 2.5</th>
                                        <th onClick={() => sortTableByColIndex(39)} className="normalOrOver25">%</th>
                                        <th onClick={() => sortTableByColIndex(40)} className="normalOrOver25"></th>

                                        <th onClick={() => sortTableByColIndex(41)} className="normalOrGg">1X2 or GG</th>
                                        <th onClick={() => sortTableByColIndex(42)} className="normalOrGg">%</th>
                                        <th onClick={() => sortTableByColIndex(43)} className="normalOrGg"></th>

                                        <th onClick={() => sortTableByColIndex(44)} className="over25OrGg">GG or OU 2.5</th>
                                        <th onClick={() => sortTableByColIndex(45)} className="over25OrGg">%</th>
                                        <th onClick={() => sortTableByColIndex(46)} className="over25OrGg"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        fixtures && fixtures.map((fixture, index) => (
                                                <tr onClick={() => handleRowClick(fixture)} key={index} onDoubleClick={() => setShowH2h(!showH2h)} /*onMouseEnter={(event) => onFixtureRowHover(fixture, event)} onMouseLeave={onFixtureRowLeave}*/
                                                    className={selectedRowData === fixture ? 'selected-row' : index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                    <td>{fixture?.date} {fixture?.time}</td>
                                                    <td className="text-align-right">
                                                        <label>{`${fixture?.homeTeam} [${!fixture?.homeTeamPosition ? 0 : fixture?.homeTeamPosition}]`}</label>
                                                        {
                                                            getHomeStats(showH2h ? fixture?.homeTeamH2hStats : fixture?.homeTeamStats)
                                                        }
                                                        <span>{`${!fixture?.homeWinsOdd ? '-' : fixture?.homeWinsOdd}`}</span>
                                                    </td>
                                                    <td className="text-align-center">
                                                        <label>
                                                            {(fixture?.homeGoals === null ? '' : fixture?.homeGoals.toString()).concat(' - ').concat(fixture?.awayGoals === null ? '' : fixture?.awayGoals.toString())}
                                                        </label>
                                                        <br/>
                                                        <label style={{fontSize: "xx-small"}}>
                                                            {
                                                                (fixture?.homeGoals != null && fixture?.awayGoals != null) &&
                                                                `(${(fixture?.homeGoalsHt === null ? '' : fixture?.homeGoalsHt.toString()).concat(' - ').concat(fixture?.awayGoalsHt === null ? '' : fixture?.awayGoalsHt.toString())})`
                                                            }
                                                        </label>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <span>{`${!fixture?.drawOdd ? '-' : fixture?.drawOdd}`}</span>
                                                    </td>
                                                    <td className="text-align-left">
                                                        <label>{`[${!fixture?.awayTeamPosition ? 0 : fixture?.awayTeamPosition}] ${fixture?.awayTeam}`}</label>
                                                        {
                                                            getAwayStats(showH2h ? fixture?.awayTeamH2hStats : fixture?.awayTeamStats)
                                                        }
                                                        <span>{`${!fixture?.awayWinsOdd ? '-' : fixture?.awayWinsOdd}`}</span>
                                                    </td>
                                                    <td className="text-align-center">{fixture?.homeGoalsExpectancy} - {fixture?.awayGoalsExpectancy}</td>

                                                    <td className="normal">{fixture?.normalTip}</td>
                                                    <td className="normal">{fixture?.normalTipProbability}</td>
                                                    <td className="normal">{fixture?.normalStatus}</td>

                                                    <td className="gg">{fixture?.ggTip}</td>
                                                    <td className="gg">{fixture?.ggTipProbability}</td>
                                                    <td className="gg">{fixture?.ggStatus}</td>

                                                    <td className="ou05">{fixture?.ou05Tip}</td>
                                                    <td className="ou05">{fixture?.ou05TipProbability}</td>
                                                    <td className="ou05">{fixture?.ou05Status}</td>

                                                    <td className="ou15">{fixture?.ou15Tip}</td>
                                                    <td className="ou15">{fixture?.ou15TipProbability}</td>
                                                    <td className="ou15">{fixture?.ou15Status}</td>

                                                    <td className="ou25">{fixture?.ou25Tip}</td>
                                                    <td className="ou25">{fixture?.ou25TipProbability}</td>
                                                    <td className="ou25">{fixture?.ou25Status}</td>

                                                    <td className="ou35">{fixture?.ou35Tip}</td>
                                                    <td className="ou35">{fixture?.ou35TipProbability}</td>
                                                    <td className="ou35">{fixture?.ou35Status}</td>

                                                    <td className="ou45">{fixture?.ou45Tip}</td>
                                                    <td className="ou45">{fixture?.ou45TipProbability}</td>
                                                    <td className="ou45">{fixture?.ou45Status}</td>

                                                    <td className="ou55">{fixture?.ou55Tip}</td>
                                                    <td className="ou55">{fixture?.ou55TipProbability}</td>
                                                    <td className="ou55">{fixture?.ou55Status}</td>

                                                    <td className="dc">{fixture?.dcTip}</td>
                                                    <td className="dc">{fixture?.dcTipProbability}</td>
                                                    <td className="dc">{fixture?.dcStatus}</td>

                                                    <td className="dcOu15">{fixture?.dcOu15Tip}</td>
                                                    <td className="dcOu15">{fixture?.dcOu15TipProbability}</td>
                                                    <td className="dcOu15">{fixture?.dcOu15Status}</td>

                                                    <td id="correctScoreTip" className="correctScore">{fixture?.correctScoreHomeGoals===null ? '' : fixture?.correctScoreHomeGoals} - {fixture?.correctScoreAwayGoals===null ? '' : fixture?.correctScoreAwayGoals}</td>
                                                    <td className="correctScore">{fixture?.correctScoreProbability}</td>
                                                    <td className="correctScore">{fixture?.correctScoreStatus}</td>

                                                    <td className="normalOrOver25">{fixture?.normalOrOver25Tip}</td>
                                                    <td className="normalOrOver25">{fixture?.normalOrOver25TipProbability}</td>
                                                    <td className="normalOrOver25">{fixture?.normalOrOver25Status}</td>

                                                    <td className="normalOrGg">{fixture?.normalOrGgTip}</td>
                                                    <td className="normalOrGg">{fixture?.normalOrGgTipProbability}</td>
                                                    <td className="normalOrGg">{fixture?.normalOrGgStatus}</td>

                                                    <td className="over25OrGg">{fixture?.over25OrGgTip}</td>
                                                    <td className="over25OrGg">{fixture?.over25OrGgTipProbability}</td>
                                                    <td className="over25OrGg">{fixture?.over25OrGgStatus}</td>
                                                </tr>
                                            )
                                        )
                                    }
                                    </tbody>
                                </table>
                                {
                                    selectedRowData &&
                                    <div className={`row-details ${selectedRowData ? 'show' : ''}`}>
                                        { window.innerWidth <= 500 &&
                                            <FontAwesomeIcon style={{cursor:"pointer", fontSize: "large", position: "fixed", right:30}} icon={faClose} onClick={()=> setSelectedRowData(null)}/>
                                        }
                                        {
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th colSpan={5} style={{textAlign: "center"}}>
                                                        <label>H2H</label>
                                                        <label>{selectedRowData.homeWinsCount}</label>
                                                        <select style={{float: "right"}} value={h2hCount.toString()} onChange={(e)=> setH2hCount(e.target.value === 'all' ? 1000 : parseInt(e.target.value))}>
                                                            <option>3</option>
                                                            <option>6</option>
                                                            <option>9</option>
                                                            <option>12</option>
                                                            <option>all</option>
                                                        </select>

                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    selectedRowData?.latestGames?.filter(latest => selectedRowData?.homeTeam?.concat(selectedRowData?.awayTeam)?.includes(latest?.homeTeam) &&
                                                        selectedRowData?.homeTeam?.concat(selectedRowData?.awayTeam)?.includes(latest?.awayTeam)
                                                    )?.sort((a, b) =>
                                                        parse(b?.date, yyyyMMddFormatter, new Date()) - parse(a?.date, yyyyMMddFormatter, new Date())
                                                    )?.slice(0, h2hCount)?.map((game, index) => {
                                                        return <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                            <td width={'25%'}>{game?.date}</td>
                                                            <td width={'35%'} style={{textAlign:"right", fontWeight: selectedRowData?.homeTeam === game?.homeTeam ? 'bold' : null}}>{game?.homeTeam}</td>
                                                            <td width={'20%'} style={{whiteSpace: "nowrap"}}>
                                                                <label>
                                                                    {game?.homeGoals} - {game?.awayGoals}
                                                                </label> <br/>
                                                                <label style={{fontSize: "xx-small"}}>
                                                                    {
                                                                        (game?.homeGoalsHt != null && game?.awayGoalsHt != null) &&
                                                                        `(${(game?.homeGoalsHt === null ? '' : game?.homeGoalsHt.toString()).concat(' - ').concat(game?.awayGoalsHt === null ? '' : game?.awayGoalsHt.toString())})`

                                                                    }
                                                                </label>
                                                            </td>
                                                            <td width={'35%'} style={{fontWeight: selectedRowData?.homeTeam === game?.awayTeam ? 'bold' : null}}>{game?.awayTeam}</td>
                                                            <td width={'5%'}><span className={`result-span ${
                                                                fixtureResult(selectedRowData?.homeTeam, game)?.normal === 'D' ? 'draw-indicator' :
                                                                    fixtureResult(selectedRowData?.homeTeam, game)?.normal === 'L' ? 'lost-indicator' :
                                                                        fixtureResult(selectedRowData?.homeTeam, game)?.normal === 'W' ? 'won-indicator' : null
                                                            }`}
                                                            >{fixtureResult(selectedRowData?.homeTeam, game)?.normal}</span></td>
                                                        </tr>
                                                    })
                                                }

                                                <tr>
                                                    <th colSpan={6} style={{textAlign: "center"}}>
                                                        <label>{selectedRowData?.homeTeam}</label>
                                                        <select style={{float: "right"}} value={homeTeamFixtureCount} onChange={(e)=> setHomeTeamFixtureCount(e.target.value === 'all' ? 1000 : parseInt(e.target.value))}>
                                                            <option>3</option>
                                                            <option>6</option>
                                                            <option>9</option>
                                                            <option>12</option>
                                                            <option>all</option>
                                                        </select>
                                                    </th>
                                                </tr>
                                                {
                                                    selectedRowData?.latestGames?.filter(latest => latest?.homeTeam?.concat(latest?.awayTeam)?.includes(selectedRowData?.homeTeam)
                                                    )?.sort((a, b) =>
                                                        parse(b?.date, yyyyMMddFormatter, new Date()) - parse(a?.date, yyyyMMddFormatter, new Date())
                                                    )?.slice(0, homeTeamFixtureCount)?.map((game, index) => {
                                                        return <tr key={index}  className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                            <td>{game?.date}</td>
                                                            <td>{game?.homeTeam}</td>
                                                            <td width={'20%'} style={{whiteSpace: "nowrap"}}>
                                                                <label>
                                                                    {game?.homeGoals} - {game?.awayGoals}
                                                                </label> <br/>
                                                                <label style={{fontSize: "xx-small"}}>
                                                                    {
                                                                        (game?.homeGoalsHt != null && game?.awayGoalsHt != null) &&
                                                                        `(${(game?.homeGoalsHt === null ? '' : game?.homeGoalsHt.toString()).concat(' - ').concat(game?.awayGoalsHt === null ? '' : game?.awayGoalsHt.toString())})`

                                                                    }
                                                                </label>
                                                            </td>
                                                            <td>{game?.awayTeam}</td>
                                                            <td><span className={`result-span ${
                                                                fixtureResult(selectedRowData?.homeTeam, game)?.normal === 'D' ? 'draw-indicator' :
                                                                    fixtureResult(selectedRowData?.homeTeam, game)?.normal === 'L' ? 'lost-indicator' :
                                                                        fixtureResult(selectedRowData?.homeTeam, game)?.normal === 'W' ? 'won-indicator' : null
                                                            }`}
                                                            >{fixtureResult(selectedRowData?.homeTeam, game)?.normal}</span></td>
                                                        </tr>
                                                    })
                                                }

                                                <tr>
                                                    <th colSpan={6} style={{textAlign: "center"}}>
                                                        <label>{selectedRowData?.awayTeam}</label>
                                                        <select style={{float: "right"}} value={awayTeamFixtureCount} onChange={(e)=> setAwayTeamFixtureCount(e.target.value === 'all' ? 1000 : parseInt(e.target.value))}>
                                                            <option>3</option>
                                                            <option>6</option>
                                                            <option>9</option>
                                                            <option>12</option>
                                                            <option>all</option>
                                                        </select>
                                                    </th>
                                                </tr>
                                                {
                                                    selectedRowData?.latestGames?.filter(latest => latest?.homeTeam?.concat(latest?.awayTeam)?.includes(selectedRowData?.awayTeam)
                                                    )?.sort((a, b) =>
                                                        parse(b?.date, yyyyMMddFormatter, new Date()) - parse(a?.date, yyyyMMddFormatter, new Date())
                                                    )?.slice(0, awayTeamFixtureCount)?.map((game, index) => {
                                                        return <tr key={index}  className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                            <td>{game?.date}</td>
                                                            <td>{game?.homeTeam}</td>
                                                            <td width={'20%'} style={{whiteSpace: "nowrap"}}>
                                                                <label>
                                                                    {game?.homeGoals} - {game?.awayGoals}
                                                                </label> <br/>
                                                                <label style={{fontSize: "xx-small"}}>
                                                                    {
                                                                        (game?.homeGoalsHt != null && game?.awayGoalsHt != null) &&
                                                                        `(${(game?.homeGoalsHt === null ? '' : game?.homeGoalsHt.toString()).concat(' - ').concat(game?.awayGoalsHt === null ? '' : game?.awayGoalsHt.toString())})`

                                                                    }
                                                                </label>
                                                            </td>
                                                            <td>{game?.awayTeam}</td>
                                                            <td><span className={`result-span ${
                                                                fixtureResult(selectedRowData?.awayTeam, game)?.normal === 'D' ? 'draw-indicator' :
                                                                    fixtureResult(selectedRowData?.awayTeam, game)?.normal === 'L' ? 'lost-indicator' :
                                                                        fixtureResult(selectedRowData?.awayTeam, game)?.normal === 'W' ? 'won-indicator' : null
                                                            }`}
                                                            >{fixtureResult(selectedRowData?.awayTeam, game)?.normal}</span></td>
                                                        </tr>
                                                    })

                                                }

                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                }
                            </div>
                            <FixtureRowDetails
                                data={selectedRowData}
                                open={selectedRowData !== null}
                                onClose={handleCloseDialog}
                            />
                        </div>
                }
            </div>
        </div>
    );
}

export default FixturesManager