import React from 'react';
import '../common-widgets/dialogs/customDialog.module.css'
import PredictionRow from "./prediction-row";
import CustomDialog from "../common-widgets/dialogs/customDialog";

function FixtureRowDetails({open, onClose, data}) {

    let content = <table>
                        <tbody>
                        <PredictionRow prediction={data} homeTeam={data?.homeTeam} awayTeam={data?.awayTeam} />
                        <div><b>H2H</b></div>
                        {
                            data?.homeTeamPreviousPredictions && data?.homeTeamPreviousPredictions?.filter(f => (f?.homeTeam + f?.awayTeam).includes(data?.homeTeam) && (f?.homeTeam + f?.awayTeam).includes(data?.awayTeam) && f?.date !== data?.date).map((prediction) => {
                                return <PredictionRow prediction={prediction}  homeTeam={data?.homeTeam} awayTeam={data?.awayTeam}/>
                            })
                        }
                        <div><b>{data?.homeTeam}</b></div>
                        {
                            data?.homeTeamPreviousPredictions && data?.homeTeamPreviousPredictions?.filter(f => f?.date !== data?.date).map((prediction) => {
                                return <PredictionRow prediction={prediction}  homeTeam={data?.homeTeam} awayTeam={data?.awayTeam}/>
                            })
                        }
                        <div><b>{data?.awayTeam}</b></div>
                        {
                            data?.awayTeamPreviousPredictions && data?.awayTeamPreviousPredictions?.filter(f => f?.date !== data?.date).map((prediction) => {
                                return <PredictionRow prediction={prediction}  homeTeam={data?.homeTeam} awayTeam={data?.awayTeam}/>
                            })
                        }
                        </tbody>
                    </table>

    return (data && CustomDialog({open}, {onClose}, data={data}, {content}));

}

export default FixtureRowDetails;
