import React, {useEffect} from "react";
import './login.css'
import {useNavigate} from "react-router-dom";
import LoginUtil from "../react-shared-utils/user-management/authentication-util/loginUtil";
import {accessTokenUrl, clientId, clientSecret, redirectUri} from "../user-management/configurations/config";
export const loginRoute="/login";
function Login() {

    const navigate = useNavigate()

    useEffect(() => {
        const access_token = localStorage?.getItem('access_token');
        if (access_token) {
            localStorage.removeItem('access_token')
            navigate(loginRoute);
        }
    }, []);

    return (
        <LoginUtil redirectUri={redirectUri} accessTokenUrl={accessTokenUrl} clientSecret={clientSecret} clientId={clientId}/>
    );
}

export default Login;