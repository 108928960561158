import React from "react";
import spinner2 from '../../../common-widgets/spinner2.gif'
export const ddMMyyyyFormatter = 'dd-MM-yyyy';
export const yyyyMMddFormatter = 'yyyy-MM-dd';
export const yyyyMMddHHmmFormatter = 'yyyy-MM-dd HH:mm';
export const ddMMyyyyHHmmFormatter = 'dd-MM-yyyy HH:mm';

export const loading = {
    showLoading : false,
    loadingElement:
        <div style={{color:"green"}}>
            <img src={spinner2} alt="Loading..." style={{height: 20, width: 20}}/>
        </div>,
    showError : false,
    errorMessage: null,
    errorElement: <p>{this?.errorMessage}</p>
}

export const loadingSpinner =
    <div style={{color:"green"}}>
        <img src={spinner2} alt="Loading..." style={{height: 20, width: 20}}/>
    </div>
export const ResponseElement = ({description: description, isError}) => {
    return  <div className={isError || isError === null ? "error-message" : 'success-message'}>{description}</div>
}

export const notificationBadge = ({count:count, backgroundColor: backgroundColor})=>{
    return <div className="notification-badge">
        <span className="badge-number">{count}</span>
    </div>
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const SUCCESS = 6000
export const NO_RECORD_FOUND = 6004
export const FAILURE = 6005

