import React from "react";
import './filter.css'
export default function SearchFilter(props){

    const {onTextChanged} = props

    return (
        <div className={"search-container"}>
            <input className={"search-input"} placeholder="Search..." type={"text"} onChange={event => onTextChanged({changedText: event?.target?.value?.toLowerCase()})}/>
        </div>
    );
}