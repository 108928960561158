import React, {useCallback, useEffect, useState} from 'react';
import styles from './layout.module.css';
import './sideMenu.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {routes,} from "../../user-management/configurations/config";
import NavigationBar from "../navigations/navigationBar";
import Login, {loginRoute} from "../../predictions/login";

function Layout({children}) {
    const location = useLocation();
    const [pathName, setPathName] = useState(location.pathname);
    const navigate = useNavigate();
    const storedTokenObject = localStorage.getItem('access_token');

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(window.innerWidth >= 500 ? true : false);
    const [subMenuButtonPosition, setSubMenuButtonPosition] = useState({ x: 0, y: 0 });
    const [activeMenu, setActiveMenu] = useState(null);

    const toggleSubMenu = (event) => {
        setIsSubMenuOpen(!isSubMenuOpen);
        const buttonRect = event.target.getBoundingClientRect();
        setSubMenuButtonPosition({ x: buttonRect.x, y: buttonRect.y });
    };

    let sideMenuDisplay = useState( isSubMenuOpen ? 'flex' : 'none');
    const sideMenu = document.getElementById('sideMenu');

    useEffect(()=>{
        if(!storedTokenObject){
            navigate(loginRoute);
        }

        if(window.innerWidth <= 700){
            sideMenuDisplay = 'none'
        }
        if(sideMenu){
            const computedStyle = window.getComputedStyle(sideMenu);
            sideMenuDisplay = computedStyle.getPropertyValue('display');

            if(isSubMenuOpen  && window.innerWidth <= 700){
                sideMenuDisplay = 'flex';
            }else if(!activeMenu || (!isSubMenuOpen && window.innerWidth <= 700)){
                sideMenuDisplay = 'none';
            }
        }

    }, [])


    const handleSubRoutes = useCallback(() => {
        setPathName(location.pathname);
        /*if(pathName?.toLowerCase()?.includes(websiteManagementRoute.route)){
            setActiveMenu(websiteManagementRoute.route)
        }*/
        return;
    },[pathName])

    useEffect(() => {
        handleSubRoutes();
    }, [handleSubRoutes])

    const isRouteActive = (route) =>{

        const thisRouteArray = route?.split('/');
        const thisRouteLastWord = thisRouteArray[thisRouteArray.length - 1];

        const mainRouteArray = pathName.split('/');
        const mainRouteLastWord = mainRouteArray[mainRouteArray.length - 1];

        return thisRouteLastWord === mainRouteLastWord ? true : false;
    }

    const subMenuLinks = routes?.filter((route) => route?.route?.toLowerCase()?.includes(activeMenu?.toLowerCase()))
        .map((route, index) => {
            return <a
                    key={index}
                    href={route?.route}
                    className={`side-menu-button ${isRouteActive(route?.route) ? 'side-menu-button-active' : null}`}
            >
                {route?.display}
            </a>
        })

    const sideMenuMobileStyle = {
        display: sideMenuDisplay,
        position: 'absolute',
        top: subMenuButtonPosition.y + window.scrollY + 25,
        left: subMenuButtonPosition.x + window.scrollX - 190,
    }

    const [headerHeight, setHeaderHeight] = useState(0);
    useEffect(() => {
        const nav = document?.querySelector('nav');
        if (nav) {
            const height = nav?.offsetHeight;
            setHeaderHeight(height);
        }

        const handleResize = () => {
            const height = nav?.offsetHeight;
            setHeaderHeight(height);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {
                !storedTokenObject &&
                <div style={{margin:"auto"}}>
                    <Login/>
                </div>
            }
            {
                storedTokenObject &&
                <div className={styles.layout}>
                    <header>
                        <NavigationBar toggleSubMenu={toggleSubMenu}/>
                    </header>
                    <main className={styles.main} style={{marginTop: (headerHeight) + 'px'}}>
                        {
                            activeMenu && isSubMenuOpen &&
                            <div id={'sideMenu'} className={'sideMenu side-menu-container'} style={window.innerWidth <= 500 ? sideMenuMobileStyle : null}>
                                {subMenuLinks}
                            </div>
                        }
                        <div style={{backgroundColor:"transparent", width: '100%'}}>
                            {children}
                        </div>
                    </main>
                    <footer>
                        {/* Footer content */}
                    </footer>
                </div>
            }
        </div>
    );
}

export default Layout;
